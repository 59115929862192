@mixin generate-utility($utility) {
    $values: map-get($utility, values);
    
    @if type-of($values) == string or type-of($values) == list {
        $values: zip($values, $values);
    }

    @each $key, $value in $values {
        $properties: map-get($utility, property);

        // Multiple properties are possible
        @if type-of($properties) == string {
          $properties: append((), $properties);
        }

        $property-class: map-get($utility, class);

        .#{$property-class}-#{$key} {
            @each $property in $properties {
                #{$property}: $value if($enable-important-utilities, !important, null);
            }
        }
    }
}