// Root variables and configuration
:root {

    // colors
    @each $color, $value in $colors {
        --#{$variable-prefix}#{$color}: #{$value};
    }

    @each $color, $value in $theme-colors {
        --#{$variable-prefix}#{$color}: #{$value};
    }

    // breakpoints

    @each $breakpoint, $breakpoint-value in $breakpoints {
        --#{$variable-prefix}breakpoint-#{$breakpoint}: #{$breakpoint-value};
    }

    // fonts

    --#{$variable-prefix}body-font-family: #{$font-family-base};
    --#{$variable-prefix}body-font-size: #{$font-size-base};
    --#{$variable-prefix}body-font-size-lg: #{$font-size-lg};
    --#{$variable-prefix}body-font-weight: #{$font-weight-base};
    --#{$variable-prefix}body-line-height: #{$line-height-base};
    --#{$variable-prefix}body-color: #{$body-color};
    @if $body-text-align != null {
        --#{$variable-prefix}body-text-align: #{$body-text-align};
      }
    --#{$variable-prefix}body-bg: #{$body-bg};

    @if $font-size-root != null {
        --#{$variable-prefix}root-font-size: #{$font-size-root};

        @media (min-width: $breakpoint-lg) {
            --#{$variable-prefix}root-font-size: #{$font-size-root} * 1.25;
        }
    }
}