@import '../../../styles/variables.scss';

.team-sub-desc-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 12rem 1.5rem;

    & > p {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: $breakpoint-md) {
    .team-sub-desc-section {
        margin: 4rem 1.5rem;
        font-size: 0.875rem;
    }
}
