@mixin responsive-heading-font-sizes($heading-font-sizes, $breakpoints) {
    @each $breakpoint, $breakpoint-value in $breakpoints {
        $font-sizes: map-get($heading-font-sizes, $breakpoint);
        
        @if $font-sizes != null {
            @for $i from 1 to length($font-sizes) + 1{
                @media (min-width: $breakpoint-value) {
                    h#{$i} {
                        @include font-size(nth($font-sizes, $i));
                    }
                }
            }
        }
    }
}