@import '../../styles/variables.scss';


.contact-us-section {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-image: url('https://storage.googleapis.com/public-bucket.tehiru.space/images/backgrounds/preview-contact-us-section-bg-sm.png');
    background-repeat: no-repeat;
    background-size: cover;
    //background-attachment: fixed;

    color: var(--tse-white);
}

.contact-us-section-inside-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-left: 16px;
    padding-right: 16px;
}

@media (min-width: $breakpoint-md) {
    .contact-us-section {
        background-image: url('https://storage.googleapis.com/public-bucket.tehiru.space/images/backgrounds/preview-contact-us-section-bg-xl.png');
    }
}

