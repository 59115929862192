@import "mixins/image";

.fluid-img {
    @include fluid-img();
}

.bg-img {
    position: absolute;
    top: 0;
    left: 0; 
    height: 100%;
    width: 100%; 
    object-fit: cover;
}