@import '../../../styles/variables.scss';

.team-section-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.team-section-grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}

.team-section-grid-6 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 16px;
}

.team-grid-item span {
    max-width: 396px;
}

.team-section-img {
    max-width: 255px;
    width: 100%;
    height: auto;
}

@media (max-width: $breakpoint-sm) {
    .team-grid-item > * {
        max-width: 40vw;
    }
}