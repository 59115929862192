
.container {
    margin: 0 auto;
    padding: 0 $container-padding-x;
}

@each $foldpoint, $container-max-width in $container-max-widths {
    @media (min-width: map-get($breakpoints, $foldpoint)) {
        .container {
            width: $container-max-width;
        }
    }
}