.page-not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100vh;

    background: linear-gradient(180deg, var(--tse-accent) 0%, var(--tse-secondary) 100%);

    & > * {
        color: var(--tse-white)
    }
}