@import '../../styles/variables.scss';

.our-team-section {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;

    background-image: url('https://storage.googleapis.com/public-bucket.tehiru.space/images/backgrounds/out-team-sm-background.png');
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
}

@media (min-width: $breakpoint-md) {
    .our-team-section {
        background-image: url('https://storage.googleapis.com/public-bucket.tehiru.space/images/backgrounds/out-team-xl-background.png');
    }
}

.our-team-section-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    color: var(--tse-white);
}

@media (min-width: $breakpoint-lg) {
    .our-team-section-text > * {
        width: 60%;
    }
}