@import '../../../styles/variables.scss';

.tech-section {
    display: block;
    position: relative;
}

.tech-section-title {
    text-align: center;
    text-transform: uppercase;
    background: linear-gradient(180deg, #FFFFFF -7.01%, #F0F3F7 50.4%);

    padding: 2rem;
}

.tech-section-sub-title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 400;
}

.tech-section-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
    
    background-color: var(--tse-primary);
    color: var(--tse-white);

    align-items: center;
    justify-items: center;

    position: relative;
    overflow: hidden;
    overflow-anchor: none;
}

.tech-section-grid:nth-child(even) {
    background-color: var(--tse-white);
    color: var(--tse-primary);

    & > div:first-child {
        order: 2;
    }
}

.little-format{
    margin-top: 10px;
    font-size: 16px;
}

.tech-read-more {
    display: flex;
    justify-content: flex-end;
}

.tech-section-readmore {
    display: block;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    overflow-anchor: none;
    transition: all 1s ease-in;
}

.tech-section-readmore[data-visible="true"] {
    opacity: 1;
    max-height: 300px;
}

.tech-read-more-button {
    font-size: 0.875rem;
    font-weight: 500;

    border: none;
    background: none;

    text-align: center;
    cursor: pointer;
}

@media (min-width: $breakpoint-md) {
    .tech-read-more--button {
        font-size: 1rem;
    }
}
  
// swiper

.tech-section-phone {
    height: 100vh;
    color: var(--tse-white);

    z-index: 1;
    position: relative;

    overflow: hidden;
    overflow-anchor: none;

}

.tech-section-phone-content {
    position: relative;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding-left: 1.5rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
}

.tech-section-phone-img-gradiant {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;

    background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}