// Variables


// Color system

// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
$torque:  #00a3ad !default;

// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800,
  "torque":     $torque,
) !default;
// scss-docs-end colors-map

//TODO change colors
// scss-docs-start theme-color-variables
$primary:       #002C70 !default;
$primary-deep: #002866 !default;
$secondary:     #5624C4 !default;
$accent:        #00A3AD !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":        $primary,
  "primary-deep":  $primary-deep,
  "secondary":      $secondary,
  "accent":         $accent,
  "success":        $success,
  "info":           $info,
  "warning":        $warning,
  "danger":         $danger,
  "light":          $light,
  "dark":           $dark
) !default;
// scss-docs-end theme-colors-map

// Prefix for :root CSS variables

$variable-prefix:             tse- !default;

// Spacing
//
// Control the default styling of most TehiruUi elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 9,
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $primary-deep !default;
$body-text-align:           null !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-base:            'Montserrat', sans-serif !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              1rem !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                0.875rem !default;
$font-size-lg:                1.25rem !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            bold !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.4 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;

$h1-font-size:                $font-size-base * 3 !default;
$h2-font-size:                $font-size-base * 2.25 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.25 !default;
$h5-font-size:                $font-size-base * 1.125 !default;
$h6-font-size:                $font-size-base !default;

$heading-font-sizes-xl: (
  $font-size-base * 3 
  $font-size-base * 2.25
  $font-size-base * 1.75
  $font-size-base * 1.25
  $font-size-base * 1.125
);
$heading-font-sizes-lg: $heading-font-sizes-xl;
$heading-font-sizes-md: (
  $font-size-base * 2.25 
  $font-size-base * 1.825
  $font-size-base * 1.25
  $font-size-base * 1.125
  $font-size-base * 1
);
$heading-font-sizes-sm: $heading-font-sizes-md;
$heading-font-sizes-xs: $heading-font-sizes-md;

$heading-font-sizes : (
  xs: $heading-font-sizes-xs,
  sm: $heading-font-sizes-sm,
  md: $heading-font-sizes-md,
  lg: $heading-font-sizes-lg,
  xl: $heading-font-sizes-xl,
);

// scss-docs-start headings-variables
$headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family:        'Montserrat', sans-serif !default;
$headings-font-style:         null         !default;
$headings-font-weight:        $font-weight-bold !default;
$headings-line-height:        140%              !default;
$headings-color:              null              !default;
// scss-docs-end headings-variables

// scss-docs-start paragraph-variables
$paragraph-font-family:        'Montserrat', sans-serif         !default;
$paragraph-base-font-size:     null                 !default;
$paragraph-font-style:         null                 !default;
$paragraph-font-weight:        $font-weight-normal  !default;
$paragraph-line-height:        140%                 !default;
$paragraph-color:              null                 !default;
// scss-docs-end paragraph-variables

// Options

$enable-important-utilities:  true !default;

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%
) !default;
// scss-docs-end position-map

// Breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$breakpoint-xs: 0px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1440px !default;

// scss-docs-start container-max-widths
$breakpoints: (
  xs: $breakpoint-xs,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl,
) !default;
// scss-docs-end container-max-widths

// Containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;
// scss-docs-end container-max-widths

// Container padding

$container-padding-x: 0.75rem !default;

// Z index values
$z-indexs: 0 1 10 100 999;
$z-indexs-neg: -1 -10 -100 -999;
// Buttons
//
// Shared variables that are reassigned to`$btn-` specific variables.

// scss-docs-start input-btn-variables
$btn-padding-y:              $font-size-base * 0.625 !default;
$btn-padding-x:              $font-size-base * 1.5 !default;
$btn-font-family:            null !default;
$btn-font-size:              $font-size-base * 0.875 !default;
$btn-line-height:            1.2 !default;
$btn-font-weigth:            bold !default;
$btn-text-transform:         uppercase !default;  

// input

$input-padding-x:       0.375rem !default;
$input-padding-y:       0.5rem !default;
$input-font-family:       null !default;
$input-font-size:         $font-size-base !default;
$input-font-weight:       bold !default;