// Reboot
//
// Normalization of HTML elements.

*, *::after, *::before {
    box-sizing: border-box;
}

:root {
    @if $font-size-root != null {
        font-size: var(--#{$variable-prefix}root-font-size);
    }
    
    font-weight: $font-weight-normal;
    line-height: $line-height-base;
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Prevent adjustments of font size after orientation changes in iOS.
// 4. Change the default tap highlight to be completely transparent in iOS.

// scss-docs-start reboot-body-rules
body {
    margin: 0; // 1
    font-family: var(--#{$variable-prefix}body-font-family);
    @include font-size(var(--#{$variable-prefix}body-font-size));

    @media (min-width: $breakpoint-lg) {
        @include font-size(var(--#{$variable-prefix}body-font-size-lg));
    }

    font-weight: var(--#{$variable-prefix}body-font-weight);
    line-height: var(--#{$variable-prefix}body-line-height);
    color: var(--#{$variable-prefix}body-color);
    text-align: var(--#{$variable-prefix}body-text-align);
    background-color: var(--#{$variable-prefix}body-bg); // 2
    -webkit-text-size-adjust: 100%; // 3
    -webkit-tap-highlight-color: rgba($black, 0); // 4
}
// scss-docs-end reboot-body-rules

// Typography
//
// 1. Remove top margins from headings
//    By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
//    margin for easier control within type scales as it avoids margin collapsing.

%heading {
    margin-top: 0; // 1
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-style: $headings-font-style;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
}

@for $i from 1 to 7 {
    h#{$i} {
        @extend %heading;
    }

    .h#{$i} {
        @extend %heading;
    }
}
@include responsive-heading-font-sizes($heading-font-sizes, $breakpoints);

@for $i from 1 to 7 {
    .h#{$i} {
        @extend h#{$i};
    }
}

p {
    margin: 0.5rem 0;
    @include font-size($paragraph-base-font-size);
    font-family: $paragraph-font-family;
    font-style: $paragraph-font-style;
    font-weight: $paragraph-font-weight;
    line-height: $paragraph-line-height;
    color: $paragraph-color;
}

input,
textarea {
    font-family: inherit;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    border: none;

    padding: $input-padding-y $input-padding-x;
}

textarea {
    resize: none;
}