.btn {
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    font-weight: $btn-font-weigth;
    text-transform: $btn-text-transform;

    padding: $btn-padding-y $btn-padding-x;

    border: 2px solid ;
    text-decoration: none;

    background-color: transparent;
}

.btn-primary {
    color: $white;
    border-color: $white;


    &:hover {
        color: $secondary;
        background-color: $white;
    }
}

.btn-secondary {
    color: $primary;
    border-color: $primary;

    &:hover {
        color: $secondary;
        background-color: $white;
        border-color: $secondary;
    }
}