@import '../../../styles/variables.scss';


.meet-the-team-section {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: url('https://storage.googleapis.com/public-bucket.tehiru.space/images/rocket_testing_1_sm.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    background-blend-mode: screen;
}

.meet-the-team-section-opacity-container {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    background-color: var(--tse-white);
    opacity: 0.8;
}

.meet-the-team-section-opacity-container > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}

@media (min-width: $breakpoint-md) {
    .meet-the-team-section {
        background-color: var(--tse-primary);
        background-image: url('https://storage.googleapis.com/public-bucket.tehiru.space/images/rocket_testing_1.png');
    }

    .meet-the-team-section-opacity-container > div {
        width: 70%;
    }
}

@media (min-width: $breakpoint-xl) {
    .meet-the-team-section-opacity-container > div {
        width: 50%;
    }
}

