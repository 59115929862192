@import '../../styles/variables.scss';

.footer {
    background-color: var(--tse-light);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 32px;
    padding-right: 32px;
}

.footer-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    font-weight: bold;    

    gap: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.footer-icons {
    display: flex;
    align-items: center;
    gap: 12px;
}

.footer-icon {
    padding-top: 2px;
}

.footer-mailto {
    text-decoration: none;
    color: var(--tse-praimary);

    &:hover {
        opacity: 0.5;
    }
}

@media (min-width: $breakpoint-md) {
    .footer {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
}

