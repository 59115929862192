@import '../../../styles/variables.scss';


.cabapilities-section {
    display: flex;
    flex-direction: column;

    background-image: url('https://storage.googleapis.com/public-bucket.tehiru.space/images/backgrounds/capabilitiesSectionBg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;

    background-blend-mode: screen;

    padding: 3rem 2rem;
}

.cabapilities-section-container {
    text-align: start;
    text-transform: uppercase;
    color: var(--tse-white);
    margin-bottom: 5vh;

    & > p {
        margin-top: 0;
        font-weight: 300;
        font-size: 1.125rem;
    }

    & > h2 {
        margin-bottom: 0;
        font-weight: 600;
    }
}

.cabapilities-section-graph {
    margin-top: 1rem;
}

.cabapilities-section-graph-img-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.cabapilities-section-graph--img {
    width: 100%;
    max-width: 420px;
}

.cabapilities-section-graph-map {
    display: flex;
    justify-content: flex-start;

    margin-top: 1rem;
}

.cabapilities-section-graph--read {
    max-width: 100%;
}

@media (min-width: $breakpoint-md) {
    .cabapilities-section {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .cabapilities-section-container {
        margin-left: 5rem;

        & > p {
            font-size: 1.375rem;
        } 
    }

    .cabapilities-section-graph {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
    }
    
    .cabapilities-section-graph-img-container {
        display: block;
        grid-column: 2;
        width: 100%;
    }

    .cabapilities-section-graph--img {
        width: 100%;
        max-width: none;
    }
    
    .cabapilities-section-graph-map {
        display: flex;
        justify-content: flex-start;
    }
    
    .cabapilities-section-graph--read {
        max-width: 100%;
        grid-column: 3;
        max-height: 10rem;
        margin-top: 33%;
    }
}

@media (min-width: $breakpoint-xl) {
    .cabapilities-section-container {
        & > p {
            font-size: 1.375rem;
        } 
    }
}

