.page-loader {
    display: none;
    position: absolute;
    inset: 0;
    background-color: rgba($color: white, $alpha: 0.7);

    z-index: 999;

    &[data-visible="true"] {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & > img {
        max-width: 256px;
        height: auto;
    }
}