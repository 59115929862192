@import '../../styles/variables.scss';

.nav-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;    
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-left: 32px;
    padding-right: 32px;
    z-index: 1;
}

.nav-bar-logo {
    max-width: 96px;
}

.nav-bar-links {
    --gap: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: var(--gap);
}

.nav-link {
    color: white;
    text-decoration: none;

    &--active {
        font-weight: bold;
    }

    &--upper {
        text-transform: uppercase;
    }
}



.moblie-nav-toggle {
    display: none;
}

.nav-bar-links > a {
    color: white;
    text-decoration: none;
} 
@media (max-width: $breakpoint-md) {
    .nav-bar {
        padding-left: 16px;
        padding-right: 16px;
    }

    .nav-bar-links {
        --gap: 24px;
        position: fixed;
        margin: 0 auto;
        inset: 0 0 0 calc(100% - 256px);
        flex-direction: column;
        align-items: flex-start;
        padding-top: 72px;
        padding-left: 16px;
        padding-right: 16px;
        z-index: 999;

        background: var(--tse-primary);
        opacity: 0.9;
        backdrop-filter: blur(5px);

        transform: translateX(100%);
        transition: transform 350ms ease-out;
        
        & > a:last-child {
            align-self: center;
            margin-top: 16px;
        }
    }

    .nav-bar-links[data-visible="true"] {
        transform: translateX(0%);
    }

    .moblie-nav-toggle {
        display: block;
        position: absolute;
        right: 16px;
        top: 48px;
        width: 32px;
        aspect-ratio: 1;
        z-index: 9999;

        background: url(../../../public/assets/hamburger.svg);
        background-repeat: no-repeat;
        background-position: center;

        border: 0;
    }

    .moblie-nav-toggle[aria-expanded="true"] {
        background-image: url(../../../public/assets/close.svg);
        position: fixed;
    }
    
    .moblie-nav-toggle:focus-visible {
        outline: 5px solid white;
        outline-offset: 5px;
    }
}
