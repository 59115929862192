@import '../../../styles/variables.scss';

.welcome-section {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
}

.welcome-section-gradient {
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, var(--tse-accent) 0%, var(--tse-secondary) 100%);
    z-index: -2;

}

.welcome-section-text-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}

.welcome-section-text {
    color: white;
    text-transform: uppercase;
}

@media (max-width: ($breakpoint-sm * 0.75)) {
    h1 {
        font-size: 1.25rem;
    }
}

.welcome-section-text > span {
    font-size: inherit;
}

.description-section {
    display: flex;
    align-items: flex-start;

    position: relative;
}

.rocket-image-container {
    height: 100%;
    max-height: 85%;

    & > img {
        position: absolute;
        width: 100%;
        right: 0;
        bottom: 0;
    }
}


@media (min-width: $breakpoint-xs) {
    .welcome-section-text-container {
        align-items: center;
    }

    .description-section {
        padding-left: 24px;
        padding-top: 64px;
        padding-bottom: 64px;
    }

    .rocket-image-container {
        min-width: calc((100% + 40vh) / 5 + -17.5vw);

        & > img {
            height: calc(100% + 40vh);
            right: -12vw;
            max-width: calc((100% + 40vh) / 5);
        }
    }
}

@media (max-width: 420px) {
    .description-section {
        font-size: 0.9375rem;

        & .h3 {
            font-size: 1.125rem;
        }
    }
}

@media (min-width: 420px) {
    .welcome-section-text-container {
        align-items: center;
    }

    .description-section {
        padding-left: 32px;
    }

    .rocket-image-container {
        min-width: calc((100% + 40vh) / 5);

        & > img {
            height: calc(100% + 40vh);
            right: 4vw;
            max-width: calc((100% + 40vh) / 5);
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .welcome-section-text-container {
        align-items: flex-start;
        padding-left: 32px;
    }

    .description-section {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .rocket-image-container {
        min-width: calc((100% + 70vh) / 6.5 + 4vw);


        & > img {
            height: calc(100% + 70vh);
            max-width: calc((100% + 70vh) / 6.5);
        }
    }
}

@media (min-width: $breakpoint-md) {
    .welcome-section-text-container {
        padding-left: 128px;
    }
    .description-section {
        padding-left: 128px;
        padding-top: 120px;
        padding-bottom: 120px;
    }

     .rocket-image-container {
         & > img {
             right: calc(4vw);
         }
     }
}

@media (min-width: $breakpoint-lg) {
    
    .welcome-section-text-container {
        padding-left: 0;
        align-self: center;
    }

    .description-section {
        & > p {
            max-width: 840px;
        }
    }

    .rocket-image-container {
        min-width: calc((100% + 70vh) / 6.5 + 2vw);

        & > img {
            right: calc(2vw);
            height: calc(100% + 70vh);
            width: calc((100% + 70vh) / 6.5);
        }
    }
}

@media (min-width: $breakpoint-xl) {
    
    .welcome-section-text-container {
        padding-left: 0;
        align-self: center;
    }

    .description-section {
        padding-left: 256px;
        padding-top: 220px;
        padding-bottom: 220px;
    }
}
