@import '../../../styles/variables.scss';

.core-principles-section {
    background: linear-gradient(180deg, #FFFFFF -7.01%, #F0F3F7 50.4%);
}

.core-principles-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 2rem;
    padding-bottom: 2rem;
}

.core-principles-section-header {
    font-weight: 600;
    text-transform: uppercase;
}

.core-principles-item  {
    // display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    max-width: 372px;
}

.core-principles-item-header {
    margin-top: 1rem;
    margin-bottom: 0;
    text-transform: uppercase;
}

.core-principles-item--img {
    max-width: 55%;
}

@media (min-width: 960px) {
    .core-principles-section-container {
        padding-bottom: 5rem;
        width: auto;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .core-principles-section-header {
        align-self: flex-start;
    }

    .core-principles-items-layout {
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        width: 100%;
    }

    .core-principles-item {
        align-items: flex-start;
        text-align: start;
    }

    .core-principles-item--img {
        width: 70%;
        max-width: fit-content;
    }

    .core-principles-item-header {
        font-size: 1.25rem;
    }

    .core-principles-item-p {
        font-size: 1rem;    
    }
}

@media (min-width: $breakpoint-xl) {
    .core-principles-section-container {
        padding-left: 0;
        padding-right: 0;
        width: 1400px;
    }

    .core-principles-item-header {
        font-size: 1.75rem;
    }

    .core-principles-item-p {
        font-size: 1.25rem;
    }
}

