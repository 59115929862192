@import '../../styles/variables.scss';

.floating-button {
	position:fixed;
    z-index: 999;
	width:60px;
	height:60px;
	bottom:32px;
	right:32px;
	background-color:#2095f2;
	color:#FFF;
    font-weight: 600;
    font-family: inherit;
    letter-spacing: 0.125px;
	border-radius:50px;
    border-color: #2095f2;
    box-shadow: 5px 5px 2px #757575;

    &:hover {
        opacity: 0.9;
    }
}

@media (min-width: $breakpoint-md) {
    .floating-button {
        width:96px;
        height:54px;
        border-radius: 20px;
        box-shadow: 2px 2px 2px #757575;
    }
}

