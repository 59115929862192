@import '../../styles/variables.scss';

.invest-page-contianer {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-left: 32px;
    padding-right: 32px;

    background-image: url('https://storage.googleapis.com/public-bucket.tehiru.space/images/backgrounds/contact-us-background-sm.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    background-attachment: fixed;

    color: var(--tse-white);
}

.invest-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    gap: 16px;
}

@media (min-width: $breakpoint-md) {
    .invest-page-contianer {
        background-image: url('https://storage.googleapis.com/public-bucket.tehiru.space/images/backgrounds/contact-us-background-xl.png');
    }
}

@media (min-width: $breakpoint-lg) {
    .invest-page-contianer {
        padding-left: 0;
        padding-right: 0;
    }

    .invest-grid {
        max-width: 960px;
    }
}

@media (min-width: $breakpoint-xl) {
    .invest-grid {
        max-width: 1140px;
    }
}

@media (min-width: $breakpoint-md) {
    .invest-page-contianer {
        background-image: url('https://storage.googleapis.com/public-bucket.tehiru.space/images/backgrounds/contact-us-background-xl.png');
    }

    .invest-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
    }
}

.invest-page-form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    font-size: 1.25rem;
}

.form-control {
    width: 100%;
    color: var(--tse-secondary) !important;
    opacity: 0.8;

    &::placeholder {
        color: var(--tse-secondary) !important;
    }
    
    &[data-not-empty="true"] {
        color: var(--tse-primary) !important;
        opacity: 1;
    }

    &:focus {
        color: var(--tse-primary) !important;
        opacity: 1;
        outline: none;
        border-bottom: 2px solid var(--tse-primary);

        &::placeholder {
            color: var(--tse-primary) !important;
            opacity: 1;
        }
    }
}

.invest-page-form > textarea {
    height: 2.25rem;
}

@media (min-width: $breakpoint-md) {
    .invest-page-form > textarea {
        height: 8rem;
    }
}


.form-row {
    display: flex;
    gap: 16px;
}
